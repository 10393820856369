/* eslint-disable jsx-a11y/media-has-caption, react/no-danger, jsx-a11y/no-noninteractive-element-interactions */
// @flow
import React from 'react';
import cx from 'classnames';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import { Box } from '@material-ui/core';
import SectionQRAndAppDownload from 'src/components/Paidycard/SectionQRAndAppDownload';
import QrAndBadgesSwitch from 'src/components/QrAndBadgesSwitch';
import { DYNAMICK_LINK, SITE_METADATA } from '../constants';
import Layout from '../components/Layout/Layout';
import styles from '../styles/pages/paidycard.module.scss';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import HorizontalScrollWithNavigation from '../components/Paidycard/HorizontalScrollWithNavigation';

export const query = graphql`
  query {
    bannerPC: file(relativePath: { eq: "hero-paidycard.png" }) {
      childImageSharp {
        fluid(maxWidth: 4800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bannerSP: file(relativePath: { eq: "hero-paidycard-sp.png" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paidycardVirtual: file(relativePath: { eq: "paidycard-virtualcard.png" }) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paidycardRealPC: file(relativePath: { eq: "paidycard-realcard.png" }) {
      childImageSharp {
        fluid(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    paidycardRealSP: file(relativePath: { eq: "paidycard-realcard-sp.png" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const PaidycardPage = ({ location, data }) => {
  const bannerSources = [
    {
      ...data.bannerSP.childImageSharp.fluid,
      media: '(max-width: 1024px)',
    },
    {
      ...data.bannerPC.childImageSharp.fluid,
      media: '(max-width: 4800px)',
    },
  ];

  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.paidycard.title,
        description: SITE_METADATA.paidycard.description,
      }}
    >
      <header className={styles.header}>
        <div className={styles.bannerWrapper}>
          <Img
            className={styles.banner}
            fluid={bannerSources}
            alt="banner"
            style={{ height: '100%', width: '100%' }}
            imgStyle={{ objectFit: 'cover' }}
          />
          <h2 className={styles.headerTitle}>ペイディカード</h2>
        </div>
      </header>
      <Breadcrumb location={location} backgroundColor="#fafafa" />
      <section className={cx(styles.section, styles.sectionWhite)}>
        <div className={styles.pc}>
          <div className={cx(styles.twoColumnsRow, styles.firstSection)}>
            <Img
              fluid={data.paidycardVirtual.childImageSharp.fluid}
              alt="スマホ用ペイディカード"
              className={styles.leftImage}
            />
            <div className={styles.rightSectionTitleContainer}>
              <h3
                className={cx(
                  styles.sectionTitle,
                  styles.sectionTitleWithDescription
                )}
              >
                ペイディカードとは
              </h3>
              <span>
                Visaマークのあるお店でクレジットカードとしてお買い物を楽しめます。ネットショップで使えるバーチャルカードは最短5分で無料・即日発行。さらにリアルカードを発行すると、街のお店でも使えます。
              </span>
            </div>
          </div>
        </div>
        <div className={styles.sp}>
          <div className={cx(styles.twoColumnsRow, styles.firstSection)}>
            <h3
              className={cx(
                styles.sectionTitle,
                styles.sectionTitleWithDescription
              )}
            >
              ペイディカードとは
            </h3>
            <Img
              fluid={data.paidycardVirtual.childImageSharp.fluid}
              alt="スマホ用ペイディカード"
              className={styles.leftImage}
            />
            <span className={styles.spPadding}>
              Visaマークのあるお店でクレジットカードとしてお買い物を楽しめます。ネットショップで使えるバーチャルカードは最短5分で無料・即日発行。さらにリアルカードを発行すると、街のお店でも使えます。
            </span>
          </div>
        </div>
        <SectionQRAndAppDownload />
        <Box mt={11}>
          <div className={styles.featureRow}>
            <figure className={styles.featureRowItem}>
              <img
                src={require('../images/paidycard-feature1.svg')}
                alt="ペイディカードの特徴1"
                width="184"
                height="184"
                className={styles.featureImage}
              />
              <figcaption className={styles.featureRowItemCaption}>
                年会費も発行手数料も無料
                <br />
                <br />
              </figcaption>
            </figure>
            <figure className={styles.featureRowItem}>
              <img
                src={require('../images/paidycard-feature2.svg')}
                alt="ペイディカードの特徴2"
                width="184"
                height="184"
                className={styles.featureImage}
              />
              <figcaption className={styles.featureRowItemCaption}>
                バーチャルカードを最短5分で即日発行
                <br />
                <br />
              </figcaption>
            </figure>
            <figure className={styles.featureRowItem}>
              <img
                src={require('../images/paidycard-feature3.svg')}
                alt="ペイディカードの特徴3"
                width="184"
                height="184"
                className={styles.featureImage}
              />
              <figcaption className={styles.featureRowItemCaption}>
                Visaマークのお店ならどこでも使える。
                <br />
                <br />
              </figcaption>
            </figure>
          </div>
        </Box>
      </section>
      <section className={cx(styles.section, styles.sectionGray)}>
        <div className={styles.sectionFlexColumn}>
          <h3
            className={cx(
              styles.sectionTitle,
              styles.spPadding,
              styles.ignoreBrPc
            )}
          >
            バーチャルカードの
            <br />
            ご利用方法
          </h3>
          <HorizontalScrollWithNavigation
            contentClassName={styles.howToRowItem}
          >
            <div className={styles.howToRowItem}>
              <div className={styles.howToRowItemFigure}>
                <QrAndBadgesSwitch
                  qrCodeImg={require('src/images/paidycard/qr.png')}
                  url={DYNAMICK_LINK.CARDPAGE}
                  qrUrl={DYNAMICK_LINK.CARDQR}
                />
              </div>
              <div>
                <p className={styles.howToItemCaptionWithLink}>
                  ペイディアプリから本人確認をして、ペイディカードを発行する。
                </p>
                {/* TODO temporal */}
                <Link to="/guide/#kyc" className={styles.link}>
                  本人確認の詳細はこちら
                </Link>
              </div>
            </div>
            <img
              src={require('../images/paidycard-right-arrow.svg')}
              alt={'右矢印'}
              width={32}
              height={32}
              className={styles.howToRowRightArrow}
            />
            <figure className={styles.howToRowItem}>
              <img
                src={require('../images/paidycard-how-to-item2.svg')}
                alt={'ペイディカードの画像'}
                width="184"
                height="184"
                className={styles.howToRowItemFigure}
              />
              <figcaption className={styles.featureRowItemCaption}>
                ペイディアプリでカード番号をコピー。
              </figcaption>
            </figure>
            <img
              src={require('../images/paidycard-right-arrow.svg')}
              alt={'右矢印'}
              width={32}
              height={32}
              className={styles.howToRowRightArrow}
            />
            <figure className={styles.howToRowItem}>
              <img
                src={require('../images/paidycard-how-to-item3.svg')}
                alt={'お支払い方法登録の画像'}
                width="184"
                height="184"
                className={styles.howToRowItemFigure}
                objectFit="contain"
              />
              <figcaption className={styles.featureRowItemCaption}>
                ネットショップのお支払い方法に登録して完了。
              </figcaption>
            </figure>
          </HorizontalScrollWithNavigation>
        </div>
      </section>
      <section className={styles.section}>
        <div className={styles.pc}>
          <div className={cx(styles.twoColumnsRow, styles.thirdSection)}>
            <Img
              fluid={data.paidycardRealPC.childImageSharp.fluid}
              alt="リアルカードの画像"
              className={styles.leftImage}
            />
            <div className={styles.rightSectionTitleContainer}>
              <h3
                className={cx(
                  styles.sectionTitle,
                  styles.spPadding,
                  styles.sectionTitleWithDescription
                )}
              >
                リアルカードを発行すると
                <br />
                街のお店でも利用可能に
              </h3>
              <span>
                リアルカードを発行すると、Visaマークのある街のお店でもペイディが使えるようになります。
              </span>
            </div>
          </div>
        </div>
        <div className={styles.sp}>
          <div className={cx(styles.twoColumnsRow, styles.thirdSection)}>
            <h3
              className={cx(
                styles.sectionTitle,
                styles.spPadding,
                styles.sectionTitleWithDescription
              )}
            >
              リアルカードを発行すると
              <br />
              街のお店でも利用可能に
            </h3>
            <Img
              fluid={data.paidycardRealSP.childImageSharp.fluid}
              alt="リアルカードの画像"
              className={styles.leftImage}
            />
            <span className={styles.spPadding}>
              リアルカードを発行すると、Visaマークのある街のお店でもペイディが使えるようになります。
            </span>
          </div>
        </div>
      </section>
      <section className={cx(styles.section, styles.sectionGray)}>
        <div className={styles.sectionFlexColumn}>
          <h3
            className={cx(
              styles.sectionTitle,
              styles.spPadding,
              styles.fourthSectionTitle
            )}
          >
            リアルカードの発行方法
          </h3>
          <HorizontalScrollWithNavigation
            contentClassName={styles.howToRowItem}
          >
            <div className={styles.howToRowItem}>
              <div className={styles.howToRowItemFigure}>
                <QrAndBadgesSwitch
                  qrCodeImg={require('src/images/paidycard/qr.png')}
                  url={DYNAMICK_LINK.CARDPAGE}
                  qrUrl={DYNAMICK_LINK.CARDQR}
                />
              </div>
              <div>
                <p className={styles.howToItemCaptionWithLink}>
                  ペイディアプリから本人確認をする。
                </p>
                <Link to="/guide/#kyc" className={styles.link}>
                  本人確認の詳細はこちら
                </Link>
              </div>
            </div>
            <img
              src={require('../images/paidycard-right-arrow.svg')}
              alt={'右矢印'}
              width={32}
              height={32}
              className={styles.howToRowRightArrow}
            />
            <figure className={styles.howToRowItem}>
              <img
                src={require('../images/paidycard-how-to-issue-card-item2.svg')}
                alt={'スマートフォンを用いたリアルカード申込み画面'}
                width="184"
                height="184"
                className={styles.howToRowItemFigure}
              />
              <figcaption className={styles.featureRowItemCaption}>
                アプリからリアルカードを申し込む。
              </figcaption>
            </figure>{' '}
            <img
              src={require('../images/paidycard-right-arrow.svg')}
              alt={'右矢印'}
              width={32}
              height={32}
              className={styles.howToRowRightArrow}
            />
            <figure className={styles.howToRowItem}>
              <img
                src={require('../images/paidycard-how-to-issue-card-item3.svg')}
                alt={'リアルカードが配送されている画像'}
                width="184"
                height="184"
                className={styles.howToRowItemFigure}
              />
              <figcaption className={styles.featureRowItemCaption}>
                一週間程度でカードが自宅に届く。
              </figcaption>
            </figure>
            <img
              src={require('../images/paidycard-right-arrow.svg')}
              alt={'右矢印'}
              width={32}
              height={32}
              className={styles.howToRowRightArrow}
            />
            <figure className={styles.howToRowItem}>
              <img
                src={require('../images/paidycard-how-to-issue-card-item4.svg')}
                alt={'バーコードが表示されているスマートフォンの画像'}
                width="184"
                height="184"
                className={styles.howToRowItemFigure}
              />
              <figcaption className={styles.featureRowItemCaption}>
                アプリで有効化すると、街のお店でも利用可能になります。
              </figcaption>
            </figure>
          </HorizontalScrollWithNavigation>
        </div>
      </section>
    </Layout>
  );
};

export default PaidycardPage;
